import React from 'react'
import Layout from '../layouts'
import CommonHero from '../components/commonhero/commonhero.js'
import WebriQForm from '@webriq/gatsby-webriq-form'

class SellPage extends React.Component {
  render() {
    return (
      <Layout
        head={{
          title: 'Thinking about selling your home in Colorado Springs, CO?',
        }}
      >
        <CommonHero pageTitle="Looking to sell a home?" />
        <main className="bg-white container">
          <div className="slim-content py-5">
            <div className="text-center mb-4">
              <p>Fill up the form below to get started.</p>
            </div>
            <WebriQForm data-form-id="5c94542c38b7513aa29d9dd9">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="First Name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="Last Name"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input className="form-control" type="email" name="Email" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Phone (optional)</label>
                    <input className="form-control" type="tel" name="Phone" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Beds</label>
                    <select
                      className="form-control"
                      required=""
                      name="Bedrooms"
                    >
                      <option value="Any">Any</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5+">5+</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Pets</label>
                    <select className="form-control" required="" name="Pets">
                      <option value="Any">Any</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Garage</label>
                    <select className="form-control" required="" name="Garage">
                      <option value="Any">Any</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleFormControlTextarea1">Comments</label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      name="Comments"
                    />
                  </div>
                </div>
              </div>
              <div className="webriq-recaptcha" />
              <div className="text-left mt-4">
                <button className="alink hvr-shadow" type="submit">
                  Submit
                </button>
              </div>
            </WebriQForm>
          </div>
        </main>
      </Layout>
    )
  }
}

export default SellPage
